.fuel-form {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px 40px; /* Added extra padding to the left and right */
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional for a cleaner look */
  }
  
  .fuel-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background: #308aff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background: #256ccb;
  }
  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  