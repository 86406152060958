body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    width: 100vh;
    height: 100vw;
    background-color: #ffffff;
    /* border-radius: 10px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    transform: rotate(90deg);
    transform-origin: center;
    font-weight: bold;
    /* position: relative; */
}
.header {
    /* background-color: #d4f7d4; */
    padding: 10px;
    text-align: right;
    height: 15%;
    /* display: flex; */
    justify-content: space-between;
    align-items: right;
    /* position: relative; */
}
.header img {
    height: 100%;
    width: auto;
    /* position: relative; */
}
.pricing-table {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    
}
.pricing-table th 
{
    border: 0px solid #ff0000;
    padding: 10px;
    text-align: center;
}
.pricing-table td {
    border: 0px solid #dc0a0a;
    padding: 10px;
    text-align: center;
    
    
}
.pricing-table th {
    background-color: #ffffff;
    font-size: 2rem;
    border: px solid #ff0000;
    
}
.highlight {
    background-color: #000;
    color: rgb(239, 64, 64);
    /* padding: 5px 10px; */
    border-radius: 5px;
    font-weight: 700;
    font-size: 5rem;
}
.footer {
    /* padding: 20px; */
    font-size: 3rem;
    color: #333;
    line-height: 1.5;
    text-align: left;
    
    
}
.footer p {
    /* margin: 10px 0; */
    font-size: 1.7rem;
    margin-top: 20px;
    padding-left: 60px;
}
.footer .emergency {
    font-weight: normal;
    color: #000000;
    font-size: 2rem;
    padding-left: 60px;
}
.footer .contacts {
    font-size: 2.2rem;
    /* margin-top: 1px; */
}
.contacts {

color: rgb(5, 5, 5);
}
.contact-column {
width: 60%; /* Adjust the width of each column */
/* background-color: #308aff; */
}
@media (max-width: 768px) {
    .highlight {
        font-size: 2.2rem;
    }
    .pricing-table th, .pricing-table td {
        font-size: 5rem;
        padding: 8px;
    }
}